import React from 'react'
import { Link } from 'gatsby'
import CategoryPill from "./category.pill";

const Article = props  => (
  <Link to={`/articles/${props.uid}/`}  className="c-article">

      <div className="w-full flex lg:flex-row flex-col mb-8">
            <img className="lg:w-1/4 lg:h-36 pb-4 lg:pb-0" alt={props.data.image.alt} src={props.data.image.url} />
            <div className="lg:w-3/4 lg:ml-8">

                <h1 className="text-pink-600 text-lg text-lg">{props.data.title.text}</h1>
                <div className="mb-2">
                    <div className="leading-7 text-gray-300"
                         dangerouslySetInnerHTML={{ __html: props.data.featured_paragraph.html.substring(0, 200) + '..' }} /></div>
                <div className="flex flex-col md:flex-row text-gray-300 text-sm">
                    <span className="mr-2"><CategoryPill category={props.data.category}/></span>
                    <span className="text-indigo-400 mt-2 md:mt-0 md:ml-2 md:mr-2">{props.data.publish_date}</span>
                    <span className="text-gray-400 mt-2 md:mt-0 md:ml-2">by {props.data.author.text}</span>

                </div>
                </div>

      </div>
  </Link>
)

export default Article